(function () {
  if (!$(".accordion-lg").length) {
    return;
  }
  $(window).on("load resize", function () {
    $(window).width() > 991
      ? $(".accordion-lg .inner").show()
      : $(".accordion-lg .inner").hide();
  });

  $(".accordion-lg .trigger").on("click", function () {
    if ($(window).width() > 991) {
      return;
    }
    let $this = $(this);
    $this.hasClass("active")
      ? $this.removeClass("active").next().stop().slideUp(200)
      : $this.addClass("active").next().stop().slideDown(200);
  });
})();
