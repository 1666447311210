(function () {
  $(".variable-width").each(function () {
    let $this = $(this);
    let $prev = $(this).parent().find(".prev_card");
    let $next = $(this).parent().find(".next_card");
    let direction = false;
    if($('body').hasClass('rtl')){
      direction = true
    }
    $this.slick({
      dots: false,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      centerMode: false,
      variableWidth: true,
      nextArrow: $next,
      prevArrow: $prev,
      rtl:direction
    });
  });
})();
